export function postMethod(url, file, filename) {
    
    let postData = new Promise(resolve => {

      const form = new FormData()
      form.append('filename', filename)
      form.append('file', file)

      fetch(url, {
        body: form,
        headers: form.headers,
        method: 'POST',
      }).then(res => {
        // console.log(res)
        res.json().then(response => {
          resolve(response)
        })
        // resolve(res)
      }).catch(error => {
        // console.log(error)
        resolve(false)
      })
    })
    return postData
}


async function determineContent(url) {
  const response = await fetch(url,{cache:'no-store'});

  if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const contentType = response.headers.get('Content-Type');

  if (contentType.includes('application/json')) {
      return await response.json();
  } else if (contentType.includes('text/')) {
      // This will handle text/plain, text/html, text/css, etc.
      if (contentType.includes('vcard')) {
          return await response.blob();
      } else {
          return await response.text();
      }
  } else if (contentType.includes('image/') ||
      contentType.includes('audio/') ||
      contentType.includes('video/') ||
      contentType.includes('application/pdf') ||
      contentType.includes('application/msword') ||
      contentType.includes('application/vnd.openxmlformats-officedocument') ||
      contentType.includes('application/vnd.ms-excel') ||
      contentType.includes('application/zip') ||
      contentType.includes('application/x-rar-compressed')) {
      // This will handle common binary data types like images, audio, video, documents, etc.
      return await response.blob();
  } else if (contentType.includes('application/octet-stream')) {
      // For general binary data
      return await response.arrayBuffer();
  } else {
      throw new Error(`Unhandled content type: ${contentType}`);
  }
}

export function fetchMethod(url) {
  let fetchData = new Promise(resolve => {
    determineContent(url).then(result => {
      resolve(result)
    }).catch(err => {
      resolve(false)
    })
  })
  return fetchData
}
